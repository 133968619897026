import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "password",
    "password_confirmation",
    "length",
    "capital",
    "special",
    "number",
    "btnNext",
    "submit"
  ]
  static values = {
    passwordValidation: {
      length:  { regex: /^.{8,}.*$/ },
      number:  { regex: /^.*[0-9].*$/ },
      capital: { regex: /^.*[A-Z].*$/ },
      // eslint-disable-next-line no-useless-escape
      special: { regex: /^.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?].*$/ },
    }
  }

  connect() {
    this.element.setAttribute("novalidate", true)

    this.element.addEventListener("input", this.onInputNext)
  }

  disconnect() {
    this.element.removeEventListener("input", this.onBlur)
  }

  onInputNext = () => {
    const valid = this.validateForm()

    if (this.nextButton !== undefined && valid === true) {
      this.nextButton.removeAttribute("disabled")

      return
    }

    if (this.nextButton !== undefined && valid === false) {
      this.nextButton.setAttribute("disabled", "disabled")
    }
  }

  submit(event) {
    if (!this.validateForm()) {
      event.preventDefault()
    }
  }

  validateForm() {
    let isValid = true

    // Not using `find` because we want to validate all the fields
    this.formFields.forEach((field) => {
      if (this.shouldValidateField(field) && !this.validateField(field)) {
        isValid = false
      }
    })

    return isValid
  }

  validateField(field) {
    let isValid = false
    if (field.type != "password") {
      isValid = field.checkValidity()
    }

    if (
      field.type == "password"
      && field.id === "user_current_password"
    ) {
      isValid = field.checkValidity()
    }

    if (
      field.type == "password"
      && field.id === "user_password"
    ) {
      isValid = this.validatePassword(field)
    }

    if (
      field.type == "password"
      && field.id === "user_password_confirmation"
    ) {
      isValid = this.validatePasswordConfirmation(field)
    }

    field.classList.toggle("invalid", !isValid)
    this.refreshErrorForInvalidField(field, isValid)

    return isValid
  }

  validatePassword(field) {
    if (!field.hasAttribute("required") && field.value === "") {
      return true
    }

    let passwordIsValid = false
    let validationQty = Object.entries(this.passwordValidationValue).length

    this.uncheckedValidation("length")
    this.uncheckedValidation("capital")
    this.uncheckedValidation("special")
    this.uncheckedValidation("number")

    if (field.value.match(/^.{8,}.*$/)) {
      this.checkedValidation("length")
      passwordIsValid = true
    }

    for (let [key, value] of Object.entries(this.passwordValidationValue)) {
      if (field.value.match(value.regex)) {
        this.checkedValidation(key)
        validationQty--
      }

      if (validationQty == 0) {
        passwordIsValid = true
      }
    }

    return passwordIsValid
  }

  validatePasswordConfirmation(field) {
    if (!field.hasAttribute("required") && field.value === "") {
      return true
    }

    let isValid = true, validationMessage = ""

    if (this.password.value != this.passwordConfirmation.value) {
      isValid = false
      validationMessage = "A senha e a confirmação estão diferentes!"
    }

    field.setCustomValidity(validationMessage)

    return isValid
  }

  shouldValidateField(field) {
    return !field.disabled && !["file", "reset", "submit", "button", "hidden"].includes(field.type)
  }

  refreshErrorForInvalidField(field, isValid) {
    this.removeExistingErrorMessage(field)

    if (!isValid) {
      this.showErrorForInvalidField(field)
    }
  }

  removeExistingErrorMessage(field) {
    const fieldContainer = field.closest("div")

    if (!fieldContainer) {
      return
    }

    const existingErrorMessageElement = fieldContainer.parentNode.querySelector(".invalid-feedback")
    if (existingErrorMessageElement) {
      existingErrorMessageElement.parentNode.removeChild(existingErrorMessageElement)
    }
  }

  showErrorForInvalidField(field) {
    field.parentNode.insertAdjacentHTML("afterend", this.buildFieldErrorHtml(field))
  }

  buildFieldErrorHtml(field) {
    return `<div class="invalid-feedback d-flex">${field.validationMessage}</div>`
  }

  checkedValidation(element) {
    element += "Target"

    this[element].querySelector(".checked").hidden = false
    this[element].querySelector(".unchecked").hidden = true
  }

  uncheckedValidation(element) {
    element += "Target"

    this[element].querySelector(".checked").hidden = true
    this[element].querySelector(".unchecked").hidden = false
  }

  get formFields() {
    return Array.from(this.element.elements)
  }

  get firstInvalidField() {
    return this.formFields.find(field => !field.checkValidity())
  }

  get password() {
    return this.passwordTarget
  }

  get passwordConfirmation() {
    return this.password_confirmationTarget
  }

  get nextButton() {
    return this.element.elements["btn_next"]
  }
}
