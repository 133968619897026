import { BaseEndpoint } from "./Base"

export default class UserEndpoint extends BaseEndpoint {
  static get selector() {
    return "users.emailChanges"
  }

  create({ userId, params = {}, options = {} } = {}) {
    this.verifyData("create", { userId })

    return this.action({
      method:  "post",
      url:     `/users/${userId}/email_changes`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
