/**
 * @typedef {"get"|"put"|"patch"|"post"|"delete"} ApiMethod
 *
 * @typedef {object} ActionParam
 * @prop {ApiMethod} method,
 * @prop {string} url,
 * @prop {object} params,
 * @prop {ActionParamOptions} options
 *
 * @typedef {object} ActionParamOptions
 * @prop {boolean} unversioned
 * @prop {boolean} admin
 */

import { camelToSnakeCase } from "../../helpers/string"

export class BaseEndpoint {
  #http

  /** @param {import("axios")} http */
  constructor(http) {
    this.#http = http
  }

  /** @param {ActionParam} param0 */
  action({
    method,
    url,
    params = {},
    options: {
      unversioned = false,
      admin = false
    } = {}
  } = {}) {
    const controller = new AbortController()

    const snakizedParams = Object.fromEntries(
      Object.entries(params).map(([key, value]) => [
        camelToSnakeCase(key),
        value
      ])
    )

    const prefixParts = ["api"]

    if (!unversioned) prefixParts.push("v1")
    if (admin) prefixParts.push("admin")

    const prefix = `/${prefixParts.join("/")}`

    const requestUrl = prefix + url

    /** @type {import("axios").AxiosRequestConfig} */
    const config = {
      method,
      url: requestUrl,

      // Parâmetros de query devem ser passados como "params" e dados de _body_ como "data"
      // ref https://github.com/axios/axios#request-config
      [["get", "delete"].includes(method) ? "params" : "data"]: snakizedParams,

      signal: controller.signal
    }

    const request = this.#http.request(config)

    request.abort = function () { controller.abort() }

    return request
  }

  verifyData(method, options) {
    Object.keys(options).forEach(param => {
      if (options[param] === undefined)
        throw new Error(`SDK ${this.constructor.name} - #${method}() - "${param}" is a required prop`)
    })
  }
}

