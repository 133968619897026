import { copyWithFeedback } from "./copy"

export const CopyDirective = {
  /** @param {HTMLElement} el  */
  bind(el, { value, modifiers }) {
    el.dataset.copyValue = value
    el.addEventListener("click", () => copyWithFeedback(el, "copyValue", modifiers))
  },
  update(el, { value }) {
    el.dataset.copyValue = value
  },
  unbind(el) {
    el.removeEventListener("click", () => copyWithFeedback(el, "copyValue"))
  },
}
