import Category from "./Category"
import Course from "./Course"
import CourseCertificate from "./CourseCertificate"
import CourseEditor from "./CourseEditor"
import CourseSubscription from "./CourseSubscription"
import CourseSubscriptionBatch from "./CourseSubscriptionBatch"
import CourseSupply from "./CourseSupply"
import CpfAvailability from "./CpfAvailability"
import DataAccessLog from "./DataAccessLog"
import FormSelectionProcessSubscription from "./FormSelectionProcessSubscription"
import OrganizationSection from "./OrganizationSection"
import PermittedCourses from "./PermittedCourses"
import Registration from "./Registration"
import ReportUser from "./ReportUser"
import ReportSelectionProcessConfig from "./ReportSelectionProcessConfig"
import ReportSelectionProcessConfigSelectionProcess from "./ReportSelectionProcessConfigSelectionProcess"
import ReportUserDataExport from "./ReportUserDataExport"
import ReportUserDataExporter from "./ReportUserDataExporter"
import Role from "./Role"
import Section from "./Section"
import SectionContent from "./SectionContent"
import SelectionProcess from "./SelectionProcess"
import SelectionProcessDashboard from "./SelectionProcessDashboard"
import SelectionProcessEnrolmentPhase from "./SelectionProcessEnrolmentPhase"
import SelectionProcessPhase from "./SelectionProcessPhase"
import SelectionProcessPhaseUserSensitive from "./SelectionProcessPhaseUserSensitive"
import SubscriptionEvent from "./SubscriptionEvent"
import User from "./User"
import UserConfirmation from "./UserConfirmation"
import UserEmailChange from "./UserEmailChange"
import UserEmailChangeEvent from "./UserEmailChangeEvent"
import UserOrganization from "./UserOrganization"
import UserPassword from "./UserPassword"
import UserResendConfirmation from "./UserResendConfirmation"
import UserSensitiveData from "./UserSensitiveData"

export default {
  Category,
  Course,
  CourseCertificate,
  CourseEditor,
  CourseSubscription,
  CourseSubscriptionBatch,
  CourseSupply,
  CpfAvailability,
  DataAccessLog,
  FormSelectionProcessSubscription,
  OrganizationSection,
  PermittedCourses,
  Registration,
  ReportSelectionProcessConfig,
  ReportSelectionProcessConfigSelectionProcess,
  ReportUser,
  ReportUserDataExport,
  ReportUserDataExporter,
  Role,
  Section,
  SectionContent,
  SelectionProcess,
  SelectionProcessDashboard,
  SelectionProcessEnrolmentPhase,
  SelectionProcessPhase,
  SelectionProcessPhaseUserSensitive,
  SubscriptionEvent,
  User,
  UserConfirmation,
  UserOrganization,
  UserEmailChange,
  UserEmailChangeEvent,
  UserPassword,
  UserResendConfirmation,
  UserSensitiveData,
}
