import { BaseEndpoint } from "./Base"

export default class UserSensitiveDataEndpoint extends BaseEndpoint {
  static get selector() {
    return "userSensitiveData"
  }

  delete({ sensitiveDataId, params = {}, options = {} } = {}) {
    this.verifyData("delete", { sensitiveDataId })

    return this.action({
      method:  "delete",
      url:     `/user_sensitive_data/${sensitiveDataId}`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
