import { BaseEndpoint } from "./Base"

export default class CourseEndpoint extends BaseEndpoint {
  static get selector() {
    return "courses"
  }

  index({ params = {}, options = {} } = {}) {

    return this.action({
      method:  "get",
      url:     "/courses",
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  show({ courseId, params = {}, options = {} } = {}) {
    this.verifyData("show", { courseId })

    return this.action({
      method:  "get",
      url:     `/courses/${courseId}`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  update({ courseId, params = {}, options = {} } = {}) {
    this.verifyData("update", { courseId })

    return this.action({
      method:  "patch",
      url:     `/courses/${courseId}`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  delete({ courseId, params = {}, options = {} } = {}) {
    this.verifyData("delete", { courseId })

    return this.action({
      method:  "delete",
      url:     `/courses/${courseId}`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
