import { BaseEndpoint } from "./Base"

export default class DataAccessLogEndpoint extends BaseEndpoint {
  static get selector() {
    return "dataAccessLogs"
  }

  create({ params = {}, options = {} } = {}) {
    this.verifyData("create", {
      "params.data_access_log.viewable_id":   params.data_access_log?.viewable_id,
      "params.data_access_log.viewable_type": params.data_access_log?.viewable_type,
      "params.data_access_log.origin":        params.data_access_log?.origin,
    })

    return this.action({
      method:  "post",
      url:     "/data_access_logs",
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
