import { BaseEndpoint } from "./Base"

export default class SectionEndpoint extends BaseEndpoint {
  static get selector() {
    return "sections.contents"
  }

  index({ sectionId, params = {}, options = {} } = {}) {
    this.verifyData("index", { sectionId })

    return this.action({
      method:  "get",
      url:     `/sections/${sectionId}/contents`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  show({ contentId, params = {}, options = {} } = {}) {
    this.verifyData("show", { contentId })

    return this.action({
      method:  "get",
      url:     `/contents/${contentId}`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  update({ contentId, params = {}, options = {} } = {}) {
    this.verifyData("update", { contentId })

    return this.action({
      method:  "put",
      url:     `/contents/${contentId}`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
