import { copyToClipboard } from "../../helpers/dom-manipulation"

/**
 * @param {HTMLElement} el
 * @param {string} datasetKey
 */
export function copyWithFeedback(el, datasetKey, attachmentConfig) {
  copyToClipboard(el.dataset[datasetKey])
  attachFeedback(el, attachmentConfig)
}

/** @param {HTMLElement} el */
function attachFeedback(el, attachmentConfig) {
  const existingElement = el.querySelector("[data-copy-feedback]")
  existingElement?.remove()

  el.style.position = "relative"

  const span = document.createElement("span")
  span.dataset.copyFeedback = ""
  span.innerText = "Link copiado!"

  styleFeedback(span, attachmentConfig)

  el.appendChild(span)

  /** @type {Keyframe[]} */
  const animationKeyframes = [
    { transform: "translateX(-4px)", opacity: 0 },
    { transform: "translateX(0)", opacity: 1, offset: 0.1 },
    { transform: "translateX(0)", opacity: 1, offset: 0.9 },
    { transform: "translateX(-4px)", opacity: 0 },
  ]
  /** @type {KeyframeAnimationOptions} */
  const animationOptions = {
    duration: 2000,
  }

  const animation = span.animate(animationKeyframes, animationOptions)

  animation.finished.then(() => {
    if (!document.contains(span)) return
    span.remove()
    el.style.removeProperty("position")
  })
}

/** @param {HTMLElement} el */
function styleFeedback(el, attachmentConfig) {
  el.style.color = "black"
  el.style.whiteSpace = "nowrap"
  el.style.position = "absolute"
  el.style.opacity = 0
  el.style.right = "0px"

  if (attachmentConfig.top) {
    el.style.bottom = "calc(100% + 0.5rem)"
  } else {
    el.style.top = "calc(100% + 0.5rem)"
  }
}
