import { BaseEndpoint } from "./Base"

export default class UserOrganizationEndpoint extends BaseEndpoint {
  static get selector() {
    return "userOrganizations"
  }

  delete({ userId, params = {}, options = {} } = {}) {
    this.verifyData("delete", { userId })

    return this.action({
      method:  "delete",
      url:     `/user_organizations/${userId}`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
