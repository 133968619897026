export const maxLength = {
  validate: (value, { length = 0 } = {}) => {
    if (value === null || value === undefined || value === "") {
      return true
    }

    const lengthParsed = Number(length)

    if (isNaN(lengthParsed)) {
      return true
    }

    const maximum = Math.max(lengthParsed, Number(length), 0)


    if (typeof value === "number") {
      value = String(value)
    }

    if (!(value.length)) {
      value = Array.from(value)
    }

    return value.length <= maximum
  },

  params: ["length"],
}
