import { BaseEndpoint } from "./Base"

export default class ReportUserDataExportEndpoint extends BaseEndpoint {
  static get selector() {
    return "reports.userDataExports"
  }

  index({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "get",
      url:     "/reports/user_data_exports",
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  create({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "post",
      url:     "/reports/user_data_exports",
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
