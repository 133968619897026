import { Controller } from "stimulus"
import IMask from "imask"

export default class extends Controller {
  static targets = ["cellPhone"]

  connect() {
    this.maskedFields()
  }

  maskedFields() {
    if (this.hasCellPhoneTarget) {
      this.maskCellPhone()
    }
  }

  get cellPhone() {
    return this.cellPhoneTarget
  }

  maskCellPhone() {
    const options = {
      mask: "(00) 00000-0000"
    }

    IMask(this.cellPhone, options)
  }
}