export const randomString = () => {
  return Math.random().toString(36).substring(2, 8) + Math.random().toString(36).substring(2, 8)
}

export const camelToSnakeCase = str => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

export function formatPhoneNuber(phoneString) {
  const cleanNumber = phoneString.replace(/\D/g, "")

  const middleRegexPartAmount = cleanNumber.length === 10 ? 4 : 5

  const phoneRegex = new RegExp(
    ["(\\d{2})", `(\\d{${middleRegexPartAmount}})`, "(\\d{4})"].join(
      ""
    )
  )

  return phoneString.replace(phoneRegex, "($1) $2-$3")
}

/** @param {string} text */
export function lowerAndStripAccents(text) {
  return text.toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
}
