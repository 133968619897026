import { CopyDirective } from "../directives/copy"

const directives = {
  copy: CopyDirective,
}

/** @param {import('vue').VueConstructor} app  */
export default function initialize(app) {
  Object.keys(directives).forEach(key => {
    app.directive(key, directives[key])
  })
}
