import { BaseEndpoint } from "./Base"

export default class UserPasswordEndpoint extends BaseEndpoint {
  static get selector() {
    return "users.password"
  }

  create({ userId, params = {}, options = {} } = {}) {
    this.verifyData("create", { userId })

    return this.action({
      method:  "post",
      url:     `/users/${userId}/password`,
      params,
      options: {
        ...options,

        unversioned: true
      }
    })
  }

  update({ userId, params = {}, options = {} } = {}) {
    this.verifyData("update", { userId })

    return this.action({
      method:  "put",
      url:     `/users/${userId}/password`,
      params,
      options: {
        ...options,

        unversioned: true
      }
    })
  }
}
