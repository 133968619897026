import { BaseEndpoint } from "./Base"

export default class SelectionProcessDashboardEndpoint extends BaseEndpoint {
  static get selector() {
    return "selectionProcesses.dashboard"
  }

  show({ selectionProcessId, params = {}, options = {} } = {}) {
    this.verifyData("show", { selectionProcessId })

    return this.action({
      method:  "get",
      url:     `/selection_process/configs/${selectionProcessId}/dashboard`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
