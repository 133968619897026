import { BaseEndpoint } from "./Base"

export default class CourseSubscriptionBatchEndpoint extends BaseEndpoint {
  static get selector() {
    return "courses.subscriptionBatches"
  }

  index({ courseId, params = {}, options = {} } = {}) {
    this.verifyData("index", { courseId })

    return this.action({
      method:  "get",
      url:     `/courses/${courseId}/subscription_batches`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  show({ courseId, batchId, params = {}, options = {} } = {}) {
    this.verifyData("show", { courseId, batchId })

    return this.action({
      method:  "get",
      url:     `/courses/${courseId}/subscription_batches/${batchId}`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  create({ courseId, params = {}, options = {} } = {}) {
    this.verifyData("create", { courseId })

    return this.action({
      method:  "post",
      url:     `/courses/${courseId}/subscription_batches`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
