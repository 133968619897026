import { BaseEndpoint } from "./Base"

export default class UserEndpoint extends BaseEndpoint {
  static get selector() {
    return "users.emailChangeEvents"
  }

  index({ userId, params = {}, options = {} } = {}) {
    this.verifyData("index", { userId })

    return this.action({
      method:  "get",
      url:     `/users/${userId}/email_change_events`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
