import { BaseEndpoint } from "./Base"

export default class CourseSubscriptionEndpoint extends BaseEndpoint {
  static get selector() {
    return "courses.subscriptions"
  }

  index({ courseId, params = {}, options = {} } = {}) {
    this.verifyData("index", { courseId })

    return this.action({
      method:  "get",
      url:     `/courses/${courseId}/subscriptions`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  create({ courseId, params = {}, options = {} } = {}) {
    this.verifyData("create", { courseId })

    return this.action({
      method:  "post",
      url:     `/courses/${courseId}/subscriptions`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  delete({ courseId, subscriptionId, params = {}, options = {} } = {}) {
    this.verifyData("delete", { courseId, subscriptionId })

    return this.action({
      method:  "delete",
      url:     `/courses/${courseId}/subscriptions/${subscriptionId}`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
