import { BaseEndpoint } from "./Base"

export default class ReportUserEndpoint extends BaseEndpoint {
  static get selector() {
    return "reports.users"
  }

  index({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "get",
      url:     "/reports/users",
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  show({ userId, params = {}, options = {} } = {}) {
    this.verifyData("show", { userId })

    return this.action({
      method:  "get",
      url:     `/reports/users/${userId}`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
