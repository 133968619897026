import { BaseEndpoint } from "./Base"

export default class UserConfirmationEndpoint extends BaseEndpoint {
  static get selector() {
    return "registrations"
  }

  show({ email, params = {}, options = {} } = {}) {
    this.verifyData("show", { email })

    return this.action({
      method:  "get",
      url:     `/registrations/${email}`,
      params,
      options: {
        ...options,

        unversioned: true
      }
    })
  }
}
