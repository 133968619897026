import { BaseEndpoint } from "./Base"

export default class CourseCertificateEndpoint extends BaseEndpoint {
  static get selector() {
    return "courses.certificate"
  }

  update({ courseId, certificateNewStatus, params = {}, options = {} } = {}) {
    this.verifyData("update", { courseId, certificateNewStatus })

    return this.action({
      method:  "patch",
      url:     `/courses/${courseId}/certificate_${certificateNewStatus}`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
