import { BaseEndpoint } from "./Base"

export default class SectionEndpoint extends BaseEndpoint {
  static get selector() {
    return "sections"
  }

  index({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "get",
      url:     "/sections",
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  show({ sectionId, params = {}, options = {} } = {}) {
    this.verifyData("show", { sectionId })

    return this.action({
      method:  "get",
      url:     `/sections/${sectionId}`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  update({ sectionId, params = {}, options = {} } = {}) {
    this.verifyData("update", { sectionId })

    return this.action({
      method:  "patch",
      url:     `/sections/${sectionId}`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
