import { BaseEndpoint } from "./Base"

export default class OrganizationSectionEndpoint extends BaseEndpoint {
  static get selector() {
    return "organizations.sections"
  }

  get selector() {
    return OrganizationSectionEndpoint.selector
  }

  show({ organizationName, slug, params = {}, options = {} } = {}) {
    this.verifyData("show", { organizationName, slug })

    return this.action({
      method:  "get",
      url:     `/organizations/${organizationName}/sections/${slug}`,
      params,
      options: {
        ...options
      }
    })
  }
}
