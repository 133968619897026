import { BaseEndpoint } from "./Base"

export default class SelectionProcessPhaseUserSensitiveEndpoint extends BaseEndpoint {
  static get selector() {
    return "selectionProcesses.phases.userSensitives"
  }

  index({ selectionProcessId, phaseId, params = {}, options = {} } = {}) {
    this.verifyData("index", { selectionProcessId, phaseId })

    return this.action({
      method:  "get",
      url:     `/selection_process/configs/${selectionProcessId}/phases/${phaseId}/user_sensitives`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
