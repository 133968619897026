import Vue from "vue/dist/vue.esm"
import { configure, extend, ValidationObserver, ValidationProvider } from "vee-validate"
import * as rules from "vee-validate/dist/rules"
import { i18n } from "../i18n"
import { copyDeeply } from "../../modules/conteudo-programatico/helpers/general"

import { decimal } from "./decimal"
import { customMaxValue } from "./customMaxValue"
import { phone } from "./phone"
import { cpf } from "./cpf"
import { pastDate } from "./past-date"
import { maxLength } from "./maxLength"

Vue.component("ValidationObserver", ValidationObserver)
Vue.component("ValidationProvider", ValidationProvider)

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})

extend("custom_max_value", customMaxValue)
extend("phone", phone)
extend("cpf", cpf)
extend("past_date", pastDate)
extend("max_length", maxLength)
extend("decimal", {
  ...decimal,
  message: (fieldName, { decimals = "*", separator = "." } = {}) => {
    const field = i18n.t(`form.fields.${fieldName}`)

    let separatorMessage = copyDeeply(separator)
    if (Array.isArray(separator)) {
      const lastItem = separatorMessage.pop()

      separatorMessage = separatorMessage.map(char => `'${char}'`).join(", ") + ` ou '${lastItem}'`
    }
    else {
      separatorMessage = `'${separatorMessage}'`
    }

    return decimals === "*"
      ? `${field} deve ter casas decimais separado por ${separatorMessage}`
      : `${field} deve ter até ${decimals} casas decimais separado por ${separatorMessage}`
  },
})

configure({
  classes: {
    invalid: "is-invalid"
  },

  defaultMessage: (field, values) => {
    values._field_ = i18n.t(`form.fields.${field}`)

    if (values.target) {

      values.target = i18n.t(`form.fields.${values.target}`)
    }

    return i18n.t(`form.validation.messages.${values._rule_}`, values)
  }
})
