export async function copyToClipboard(text) {
  try {
    await window.navigator.clipboard.writeText(text)
    //
  } catch (error) {
    const predictableMessages = [
      "NotAllowedError: Write permission denied.",
      "window.navigator.clipboard is undefined"
    ]

    if (!predictableMessages.includes(error.message)) {
      // eslint-disable-next-line no-console
      console.error(error)
    }

    execCommandCopy(text)
  }
}

function execCommandCopy(text) {
  if (!document.execCommand) {
    /* eslint-disable-next-line no-console */
    throw new Error("No option to access the clipboard")
  }

  const input = document.createElement("input")
  input.type = "text"
  input.value = text
  input.style = "position: absolute; top: 10000vh; left: 10000vw"

  document.body.appendChild(input)
  input.select()
  input.setSelectionRange(0, 99999)
  document.execCommand("copy")

  document.body.removeChild(input)
  /* eslint-disable-next-line no-console */
  console.log("Text copied to clipboard")
}

export async function checkIfBrowserCanCopy() {
  try {
    if (!window.navigator?.clipboard?.writeText) throw new Error("Browser has no access to navigator clipboard")
    await window.navigator.clipboard.writeText("")

    return true
  } catch (error) {
    return Boolean(document.execCommand)
  }
}
