import { BaseEndpoint } from "./Base"

export default class FormSelectionProcessSubscriptionEndpoint extends BaseEndpoint {
  static get selector() {
    return "formSelectionProcessSubscriptions"
  }

  index({  params = {}, options = {} } = {}) {

    return this.action({
      method:  "get",
      url:     "/form/selection_process_subscriptions",
      params,
      options: {
        ...options,

        admin: true,
      }
    })
  }
}
