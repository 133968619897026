import _set from "lodash.set"
import requests from "../../constructors/api"

export class ApiClient {
  constructor(http) {
    this.http = http

    this.#createDeclarativeMethods()
  }

  #createDeclarativeMethods() {
    this.requests = Object.values(requests)
      .sort(req => req.selector)
      .reduce((allRequests, RequestClass) => {
        _set(allRequests, RequestClass.selector, new RequestClass(this.http))
        return allRequests
      }, {})
  }
}
